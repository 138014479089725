<template>
  <v-text-field
    v-model="currentValue"
    append-icon="mdi-calendar-clock"
    :label="label"
    v-bind="$attrs"
    placeholder="hh:mm"
    :rules="[rules.time, rules.required]"
  >
    <template v-slot:append>
      <v-dialog ref="menu" :close-on-content-click="false" width="auto">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" v-bind="$attrs"> mdi-calendar-clock </v-icon>
        </template>
        <v-time-picker
          format="24hr"
          color="primary"
          :header-color="color"
          v-model="currentValue"
          @click:minute="close"
        >
        </v-time-picker>
      </v-dialog>
    </template>
  </v-text-field>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TimeInput",
  props: { label: String, color: String, value: String, required: Boolean },
  data() {
    return {
      currentValue: this.value,
      rules: {
        required: (value) => !!value || !this.required || "wird benötigt.",
        time: (value) => {
          const pattern = /^$|(^([01][0-9]|2[0-3]):[0-5][0-9]$)/;
          return (
            !value ||
            pattern.test(value) ||
            "Keine gültige Uhrzeit! Bitte als hh:mm eingeben."
          );
        },
      },
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    currentValue() {
      this.$emit("input", this.currentValue);
    },
  },
  methods: {
    close() {
      this.$refs.menu.save();
    },
  },
});
</script>
