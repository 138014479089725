<template>
  <div v-if="show">
    <div v-if="canEdit">
      <v-tooltip v-if="value.dispensation" top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" v-on="on" small>dispensiert</v-chip>
        </template>
        <span>{{ value.dispensation.description }}</span>
      </v-tooltip>

      <v-tooltip v-else-if="absence" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="deleteAbsence"
            small
            depressed
            class="white--text"
            color="danger"
            v-bind="attrs"
            v-on="on"
            >abwesend</v-btn
          >
        </template>
        <span>Absenz löschen mit Klick.</span>
      </v-tooltip>
      <v-tooltip v-else top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="addAbsence"
            small
            depressed
            color="success"
            v-bind="attrs"
            v-on="on"
            >anwesend</v-btn
          >
        </template>
        <span>Absenz erfassen mit Klick.</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-chip small outlined v-if="absence" color="danger" class="white--text"
        >abwesend</v-chip
      >
      <v-chip small outlined v-else color="success">anwesend</v-chip>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "AbsenceToggleButton",
  props: {
    value: {},
    day: null,
    register: {},
  },
  computed: {
    absence() {
      return this.value.absences && this.value.absences.length > 0
        ? this.value.absences[0]
        : null;
    },
    show() {
      return (
        this.value.attendanceCheckStatus.code == "signed" ||
        this.value.attendanceCheckStatus.code == "missed"
      );
    },
    canEdit() {
      return this.$_hasRole("teacher");
    },
  },

  methods: {
    async addAbsence() {
      if (
        await this.$root.confirm({
          message: `Soll für diese Lektion eine Abwesenheit erfasst werden?`,
          color: "success",
          icon: "mdi-plus",
        })
      ) {
        await this.apiPost({
          resource: "absence/absence",
          data: {
            course: this.value.course,
            date: this.day.date,
            period: { id: this.value.periodId },
            site: this.value.site,
            student: this.register.student,
            absent: true,
          },
        });
        this.$emit("updated");
      }
    },

    async deleteAbsence() {
      if (
        await this.$root.confirm({
          message: `Soll diese Absenz gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "absence/absence",
          id: this.absence.id,
        });
        this.$emit("updated");
      }
    },
  },
});
</script>
